<template>
  <section>
    <div class="card form-body">
      <div class="card-body">
        <vDataLoader v-if="dataLoading"></vDataLoader>

        <div v-else>
          <iframe
            :src="tcnPdf"
            style="width: 100%; height: calc(100vh - 150px); border: none"
          >
            Oops! an error has occurred.
          </iframe>
        </div>
      </div>

      <div class="card-footer">
        <button
          class="btn btn-danger mr-3"
          @click="goBack()"
          style="float: right"
        >
          <i class="fa fa-arrow-left"></i> Back
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { FETCH_PDF_TCN } from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vDataLoader from "@/components/frontend/spinner.vue";

export default {
  components: {
    vDataLoader
  },
  data() {
    return {
      dataLoading: true,
      tcnPdf: "",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Enrollment List",
          route: "client.storeforward"
        },
        {
          id: 3,
          title: "Release Form",
          route: ""
        }
      ]
    };
  },
  created() {
    this.fetchTcnPdf();
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },
  methods: {
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.storeforward",
        params: { client_slug: client_slug }
      });
    },
    fetchTcnPdf() {
      this.dataLoading = true;
      this.$store
        .dispatch(FETCH_PDF_TCN, this.$route.params.tcn)
        .then(data => {
          const blob = new Blob([data], { type: "application/pdf" });
          const objectUrl = URL.createObjectURL(blob);
          this.tcnPdf = objectUrl;
          this.dataLoading = false;
          //   console.log(this.tcnPdf);
        })
        .catch(() => {
          this.dataLoading = false;
        });
    }
  }
};
</script>
